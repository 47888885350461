<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
          <TemplateFiveList :type="'check'" :list-data="listData2" :selectValue="listSelect" @change="listChange"></TemplateFiveList>
          <TemplateFiveList :type="'radio'" :list-data="listData3" :selectValue="listSelect" @change="listChange1"></TemplateFiveList>

          <TemplateFiveListCopy :list-data="listData1" :selectValue="listSelect" :type="'check'" @change="listChange"></TemplateFiveListCopy>

          <TemplateFiveList :type="'radio'" :list-data="listData" :selectValue="listSelect" @change="listChange"></TemplateFiveList>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
          </div>
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults :countText="countValue" :countTextList="countTextList" v-if="show"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          title="应激指数"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
    <!-- <p style>实验室指标</p><p style="margin-top:20px">血小板计数(×10^9/L)</p> -->
  </div>


</template>

<script>
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateFiveList from "@/components/Template5/TemplateFiveList";
import TemplateFiveListCopy from "@/components/Template5/TemplateFiveListCopy";
import CalculationResults from "@/components/CalculationResults";

export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      countTextList:[],
      countValue:'',
      listSelect: [],
      listData1: [
        {
          title: '实验室指标'
        }
      ],
      listData3: [
        {
          title: '是否有恶性血液病',
          columns: ['是', '否'],
          fenzhi: [0,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        }
      ],
      listData2:[
        {
          title: '原发病',
          columns: ['存在导致DIC的原发病'],
          fenzhi: [2] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
      ],
      listData: [
        {
          title: 'PT/APTT(s)',
          columns: ['PT延长<3且APTT延长<10', 'PT 延长≥3 或APTT 延长≥10', 'PT 延长≥6'],
          fenzhi: [0,1,2] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '纤维蛋白原(g/L)',
          columns: ['≥1.0', '<1.0'],
          fenzhi: [0,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: 'D-二聚体(mg/L)',
          columns: ['<5.0', '5~8.9', '≥9.0'],
          fenzhi: [0,2,3] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        }
      ],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      columns: ['正常或轻伤', '中度创伤', '重度创伤'],
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      fenzhiList: [],
      arr:[],
    }
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateFiveList,
    CalculationResults,
    TemplateFiveListCopy
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods:{
    listChange1 (result) {
      this.show=false
      this.listSelect = result //结果数组
      // this.fenzhiList = fenzhi  //分值数组  
      result.forEach((item)=>{
        if(item.title === '是否有恶性血液病' && item.value[0] === '否'){
          if(this.listData1.length!==1){
            var variable1 = this.listData1[2].columns[0]
            if(variable1 === '≥100'){
              return
            }
          }
          // 删除list里面的选项
          this.listSelect = this.listSelect.filter(item =>{
            return item.title !== '临床表现'  &&  item.title !== '血小板计数'
          })
          this.listData1 = [
            {
              title: '临床表现',
              columns: ['不能用原发病解释的严重或多发出血倾向', '不能用原发病解释的微循环障碍或休克', '广泛性皮肤、黏膜栓塞，灶性缺血性坏死、脱落及溃疡形成， 不明原因的肺、肾、脑等脏器功能衰竭'],
              fenzhi: [1,1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '实验室指标'
            },
            {
              title: '血小板计数',
              columns: ['≥100', '80~99', '<80','24h内下降≥50%'],
              fenzhi: [0,1,2,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
          ]
        }else if(item.title === '是否有恶性血液病' && item.value[0] === '是'){
          if(this.listData1.length!==1){
            var variable = this.listData1[2].columns[0]
            if(variable === '≥50'){
              return
            }
          }
          // 删除list里面的选项
          this.listSelect = this.listSelect.filter(item =>{
            return item.title !== '临床表现'  &&  item.title !== '血小板计数'
          })
          this.listData1 = [
            {
              title: '临床表现',
              columns: ['不能用原发病解释的微循环障碍或休克', '广泛性皮肤、黏膜栓塞，灶性缺血性坏死、脱落及溃疡形成， 不明原因的肺、肾、脑等脏器功能衰竭'],
              fenzhi: [1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '实验室指标'
            },
            {
              title: '血小板计数',
              columns: ['≥50', '<50', '24 h内下降≥50%'],
              fenzhi: [0,1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
          ]
        }
        // // 互斥
        // if(item.title === '血小板计数' && item.value.length !== 0){
        //   var variable1 = this.listData1[2].columns[0]
        //   var variable2 = this.listData1[2].columns[1]
        //   this.listSelect[index].value.forEach(item => {
        //     if(item === variable1){
        //       // 如果这时候又选择了4，就删除3选项
        //       if(this.listSelect[index].value.includes(variable2)){
        //           this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
        //           return item !== variable1
        //         });
        //       }
        //     }else if(item === variable2){
        //       if(this.listSelect[index].value.includes(variable1)){
        //           this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
        //           return item !== variable2
        //         });
        //       }
        //     }
        //   })
        // }
      })
    },
    listChange (result, fenzhi) {
      this.show=false
      this.listSelect = result //结果数组
      this.fenzhiList = fenzhi  //分值数组

      result.forEach((item,index)=>{
        if(item.title === '是否有恶性血液病' && item.value[0] === '否'){
          this.listData1 = [
            {
              title: '临床表现',
              columns: ['不能用原发病解释的严重或多发出血倾向', '不能用原发病解释的微循环障碍或休克', '广泛性皮肤、黏膜栓塞，灶性缺血性坏死、脱落及溃疡形成， 不明原因的肺、肾、脑等脏器功能衰竭'],
              fenzhi: [1,1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '实验室指标'
            },
            {
              title: '血小板计数',
              columns: ['≥100', '80~99', '<80','24h内下降≥50%'],
              fenzhi: [0,1,2,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
          ]
        }else if(item.title === '是否有恶性血液病' && item.value[0] === '是'){
          this.listData1 = [
            {
              title: '临床表现',
              columns: ['不能用原发病解释的微循环障碍或休克', '广泛性皮肤、黏膜栓塞，灶性缺血性坏死、脱落及溃疡形成， 不明原因的肺、肾、脑等脏器功能衰竭'],
              fenzhi: [1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
            {
              title: '实验室指标'
            },
            {
              title: '血小板计数',
              columns: ['≥50', '<50', '24 h内下降≥50%'],
              fenzhi: [0,1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
            },
          ]
        }
        // 互斥
        if(item.title === '血小板计数' && item.value.length !== 0){
          var variable1 = this.listData1[2].columns[0]
          var variable2 = this.listData1[2].columns[1]
          var variable3 = this.listData1[2].columns[2]
          // console.log(variable1)
          this.listSelect[index].value.forEach(item => {
            if(variable1 === '≥50'){ 
              if(item === variable1){
                if(this.listSelect[index].value.includes(variable2)){
                    this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
                    return item !== variable1
                  });
                }
              }else if(item === variable2){
                if(this.listSelect[index].value.includes(variable1)){
                    this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
                    return item !== variable2
                  });
                }
              }
            }else if (variable1 === '≥100'){
              if(item === variable1 ){
                // 如果有了1又选了2 就把1删掉  
                // 如果已经有了1 又选了2，就把1和3删掉
                if(this.listSelect[index].value.includes(variable2)){
                    this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
                    return item !== variable1 && item !== variable3
                  });
                } else if(this.listSelect[index].value.includes(variable3)){
                    this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
                    return item !== variable1 && item !== variable2
                  });
                }
              }
              else if(item === variable2){
                if(this.listSelect[index].value.includes(variable1)){
                    this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
                    return item !== variable2 && item !== variable3
                  });
                } else if(this.listSelect[index].value.includes(variable3)){
                    this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
                    return item !== variable1 && item !== variable2
                  });
                }
              }
              else if(item === variable3){
                if(this.listSelect[index].value.includes(variable1)){
                    this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
                    return item !== variable2 && item !== variable3
                  });
                } else if(this.listSelect[index].value.includes(variable2)){
                    this.listSelect[index].value = this.listSelect[index].value.filter(function(item) {
                    return item !== variable1 && item !== variable3
                  });
                }
              }
            }
          })
        }
      })
      // console.log("有？",result)
    },
    coumputTetxt () {
      this.show=true
      let fenshu = 0
      function calculateScore(data, select) {
        select.value.forEach(item => {
          const index = data.columns.indexOf(item);
          if (index !== -1) {
            fenshu += data.fenzhi[index];
          }
        });
      }

      this.listSelect.forEach(value => {
        const data = this.listData.find(d => d.title === value.title);
        const data1 = this.listData1.find(d => d.title === value.title);
        const data2 = this.listData2.find(d => d.title === value.title);
        if (data) {
          calculateScore(data, value);
        }
        if (data1) {
          calculateScore(data1, value);
        }
        if (data2) {
          calculateScore(data2, value);
        }
      });
      this.listSelect.forEach(item =>{
        if(item.title === '是否有恶性血液病' && item.value[0] === '是'){
          if (fenshu >= 6) {
            this.countText = `DIC`
          } else if (fenshu < 6) {
            this.countText = '非DIC'
          }
        }else if(item.title === '是否有恶性血液病' && item.value[0] === '否'){
          if (fenshu >= 7) {
            this.countText = `DIC`
          } else if (fenshu < 7) {
            this.countText = '非DIC'
          }
        }
      })
      this.result = fenshu
      this.countValue = `${this.result}分 ${this.countText}`
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'GFEMRFMJ',
      } 
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          this.listSelect = res.data.content.data
          // 回显listData2
          this.listSelect.forEach((item)=>{
            if(item.title === '是否有恶性血液病' && item.value[0] === '否'){
              this.listData1 = [
                {
                  title: '临床表现',
                  columns: ['不能用原发病解释的严重或多发出血倾向', '不能用原发病解释的微循环障碍或休克', '广泛性皮肤、黏膜栓塞，灶性缺血性坏死、脱落及溃疡形成， 不明原因的肺、肾、脑等脏器功能衰竭'],
                  fenzhi: [1,1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
                },
                {
                  title: '实验室指标'
                },
                {
                  title: '血小板计数',
                  columns: ['≥100', '80~99', '<80','24h内下降≥50%'],
                  fenzhi: [0,1,2,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
                },
          ]
            } else if(item.title === '是否有恶性血液病' && item.value[0] === '是'){
              this.listData1 = [
                {
                  title: '临床表现',
                  columns: ['不能用原发病解释的微循环障碍或休克', '广泛性皮肤、黏膜栓塞，灶性缺血性坏死、脱落及溃疡形成， 不明原因的肺、肾、脑等脏器功能衰竭'],
                  fenzhi: [1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
                },
                {
                  title: '实验室指标'
                },
                {
                  title: '血小板计数',
                  columns: ['≥50', '<50', '24 h内下降≥50%'],
                  fenzhi: [0,1,1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
                },
              ]
            }
          })

          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.result = res.data.result.value
            this.fenzhiList = [res.data.result.value]
          this.countText = res.data.result.result
            this.countValue = `${this.result}分 ${this.countText}`
            this.show=true
          // this.coumputTetxt()
        }
      }
    },
    async submit () {
      this.listSelect = this.listSelect.filter(function(item) {
        return  item.value.length !== 0
      });
      // console.log(this.listSelect)
      if (this.listSelect.length < 5) {
        Toast.fail('请完善选择')
        return
      }
      // 如果存在一项没有选择
      if (this.listSelect.length >= 5) {
        let result = this.listSelect.some(ele => ele.title === '血小板计数') //true
        if (!result) {
          Toast.fail('请完善选择')
          return  
        }
      }
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
      //   columnCode: this.tableData.columnCode,
        columnCode: 0,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          data: this.listSelect
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          result: this.countText,
          DisplayResults:this.countValue
        }
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: {value: this.shengao, unit: 'cm'},
          tizhong: {value: this.tizhong, unit: 'kg'},
          nianling: {value: this.nianling, unit: '岁'},
          xingbie: {value: this.xingbie == 1 ? '男' : '女'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {}
      }
      if (this.xingbie == 1) {
        data.result.nanxing = {value: this.result, unit: 'kcal'}
      } else {
        data.result.nvxing = {value: this.result, unit: 'kcal'}
      }
      postFormula
      data
      Toast
      // const res = await postFormula(data)
      // if (res.code === 0) {
      //   Toast.success('提交成功')
      // } else {
      //   Toast.fail(res.message)
      // }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
.Abutton {

}
</style>
